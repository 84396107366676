header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  background-color: rgb(33, 34, 38);
  box-shadow: #212121 0px 0px 40px;
  width: 100vw;
  backdrop-filter: blur(50px);

}

header::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./img/wave.jpg');
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
  /* Adjust the opacity value as needed */
}





main {
  margin-top: 5rem;
}

.searchbox {
  margin: 1rem;
  width: 50vw;
  height: 3rem;
  padding: 0 1.5rem;
  border: 1px solid #2b2b2b;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.25rem;
  background-color: #121212;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding-left: 40px;
  color: white;
  transition: 0.2s ease;
}

.expanded {
  font-size: 2rem;
  padding: 0 2rem;
  height: 4rem;
  margin: 2rem;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 2rem;
  bottom: 0;
  background-color: rgb(33, 34, 38);
}

/* set media breakpoint for mobile */

@media (max-width: 600px) {



  .searchbox {
    width: 80vw;
    font-size: 1rem;
    margin: 1rem;
  }

  main {
    margin-inline: 1rem;
  }
}

.button-row {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.type-button {
  margin: 0 0.5rem;
}

.active {
  background-color: #2b2b2b;
}

/* App.css */
.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 16px;
}

.button-row button {
  padding: 0.5rem 1rem;
  background-color: #8a8a8a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s ease;
}

.button-row button.active {
  background-color: white;
}