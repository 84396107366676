.word {
    margin-bottom: 4rem;
    text-align: center;

    & div {
        /* margin-bottom: 50rem; */
    }


}

.word__part {
    margin: 1rem;
}

.kanji {
    font-size: 2rem;
    font-weight: bold;
    color: hsla(0, 0%, 100%, 0.741);
}

.latin {
    font-size: 1.5rem;
    font-weight: bold;
}

.english {
    font-size: 1.5rem;
}

.definition {
    font-size: 1rem;
    max-width: 30rem;
    margin: 0 auto;
}